import request from '@/common/utils/request'
import Vue from "vue";
export function getList(params) {//管理员查询旗下列表
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/participate/listParticipateByEpidemic',
    method: 'get',
    params
  })
}


export function getrenwuList(params) {//管理员查询旗下列表的任务
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/activity/listActivityByParticipateId',
    method: 'get',
    params
  })
}

export function shenhe(data) {//披上上传图片
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/activityInfo/upload-for-others',
    method: 'post',
    data
  })
}