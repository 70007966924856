<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <el-button type="primary" size="small" @click="shangchuanForm"
                >批量上传签名图片</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>

      <el-table-column prop="username" label="姓名"> </el-table-column>
      <el-table-column prop="userTypeName" label="嘉宾类型"> </el-table-column>
      <el-table-column prop="workGroupName" label="部门"> </el-table-column>

      <el-table-column show-overflow-tooltip prop="phone" label="手机号">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="idcard" label="身份证">
      </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="参会状态"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="scope.row.status == -1">取消</template>
          <template v-else-if="scope.row.status == 0">审核中</template>
          <template v-else-if="scope.row.status == 1">审核通过</template>
          <template v-else-if="scope.row.status == 2">暂不确定</template>
          <template v-else-if="scope.row.status == 3">确认参加</template>

          <template v-else-if="scope.row.status == -2">嘉宾拒绝</template>
          <template v-else-if="scope.row.status == -3">已删除</template>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作"
        fixed="right"
        min-width="200"
      >
        <template slot-scope="scope">
          <!-- <el-button
              size="mini"
              type="primary"
              @click="handlexingcheng(scope.row)"
              >修改</el-button
            > -->
          <el-button size="mini" type="primary" @click="haEdit(scope.row)"
            >查看任务</el-button
          >

          <el-popover placement="left" width="300" trigger="click">
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
            <!-- <el-button
                type="warning"
                size="mini"
                @click="exportExcelgeren(scope.row.id)"
                >导出个人行程表</el-button
              > -->
            <el-button
              style="margin-left: 10px"
              size="mini"
              type="success"
              slot="reference"
              >更多</el-button
            >
          </el-popover>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      title="excel导入"
      :visible.sync="excelImp.dialogVisible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-row>
          <el-col :span="24">
            <a :href="baseUrl + '/YQhuiyiqian.xlsx'" target="_blank"
              >点击下载导入模板</a
            >
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="24">
            <el-upload
              class="upload-demo"
              ref="upload"
              name="file"
              :action="excelImp.action"
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              :file-list="excelImp.fileList"
              :auto-upload="false"
              :on-success="onUploadSuccess"
              :on-error="onUploadError"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx</div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="excelImp.dialogVisible = false">关 闭</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitUpload"
          :loading="excelImp.loading"
          >立即导入</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="批量上传线下疫情防疫凭证"
      :visible.sync="editFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form ref="editForm" :model="editForm" :rules="rules" :inline="true">
        <el-form-item
          label="线下疫情防疫凭证:"
          class="demo-form-inline"
          prop="singature"
        >
          <el-input v-model="form.singature" style="display: none" />
          <el-upload
            v-model="form.singature"
            action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
            :before-upload="beforeAvatarUpload"
            class="avatar-uploader"
            :headers="{
              'X-Token': token,
            }"
            :on-success="handleAvatarSuccess"
            :show-file-list="false"
          >
            <img v-if="imageUrl" class="avatar" :src="imageUrl" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <edits ref="edits" @fetch-data="getDataList" />
  </div>
</template>
    
    <script>
import { getUserTypeList } from "@/api/user_type";

import util from "@/common/utils/util.js";

import { getAllRole } from "@/api/role";
import { getToken } from "@/common/utils/auth";

import { getHotelList } from "@/api/hotel";
import { getAllUserList } from "@/api/user";
import { getPlateListWithGroup } from "@/api/plate";
import { getParticipateListwo } from "@/api/bumen";
import axios from "axios";

import { getYycompanyList } from "@/api/yy_company";
import { getFrontUserList } from "@/api/front_user";
import { getList, shenhe } from "@/api/jiabin";
import moment from "moment";
import Edits from "./model/chakanrenwu.vue";
export default {
  name: "huodongguanli",
  components: { Edits },
  data() {
    const validatesurfacePlot = (rule, value, callback) => {
      if (this.form.singature === "") {
        callback(new Error("请添加图片!!!!"));
      } else {
        callback();
      }
    };
    return {
      handlerUserList: [],
      token: "",
      imageUrl: "",
      excelImp: {
        dialogVisible: false,
        loading: false,
        fileList: [],
        action: "",
        loadingDiv: "",
      },
      baseUrl: "",

      options1: [
        {
          id: 3,
          creClasName: "审核通过",
        },
        {
          id: 4,
          creClasName: "审核不通过",
        },
      ],
      form: {
        ids: [],
        singature: "",
      }, //代上传的
      actorScore: "", //当前状态
      value1: "",
      total: 0,
      selectRows: [],

      pageSize: 10,
      userList: [],
      userTypeList: [],
      currentPage: 1,
      tableData: [],
      rules: {
        singature: [{ validator: validatesurfacePlot, trigger: "change" }],
        failCause: [
          { required: true, trigger: "blur", message: "请输入不通过原因" },
        ],
      },
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {
        id: 0,

        process: "",
      },
      editRules: {
        title: [{ required: true, message: "请输入任务标题", trigger: "blur" }],
        endTime: [
          { required: true, message: "请选择截止时间", trigger: "blur" },
        ],
      },
      loadingDiv: null,
      searchForm: {
        pageSize: 10,
        pageNum: 1,
      },
      roleList: [],
      yyCompanyList: [],
      frontUserList: [],
    };
  },
  mounted() {
    this.getDataList();
    this.getDataListwo();
    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 198;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 198;
      });
    };

    this.init();
    let t = getToken();
    this.token = t;
    this.excelImp.action =
      this.$adminApiUrl + "/participate/excel-imp?userType=0&_t=" + t;
    this.baseUrl = this.$baseUrl;
    this.uploadActionUrl = this.$adminApiUrl + "/uploadToQiNiu?_t=" + t;
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return true;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res.data);
      this.form.singature = res.data;
    },
    handleSelectionChange(val) {
      this.selectRows = val;
      const ids = this.selectRows.map((item) => item.id);
      this.form.ids = ids;
      console.log(this.form.ids);
    },
    fmtDate(d, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (!fmt) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        S: d.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    exportExcelSameName() {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/activityInfo/download", // 请求地址
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

          let fileName = "疫情信息表";

          link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },

    shangchuanForm(row) {
      if (this.selectRows.length > 0) {
        const username = this.selectRows.map((item) => item.username).join();

        this.$confirm(
          "是否确认批量上传照片:" + "(选中人名称:" + username + ")",
          "批量上传确认"
        ).then((res) => {
          console.log("queren");
          this.editFormVisible = true;
          // if ((res = "confirm")) {
          //   loadingDiv = util.showLoading("删除中...");
          //   return doDELETE({ id: row.id });
          // }
        });
      } else {
        this.$alert("未选中任何行", "错误", "vab-hey-message-error");
      }
    },
    submitUpload() {
      this.excelImp.loading = true;
      this.excelImp.loadingDiv = util.showLoading("导入中");
      this.$refs.upload.submit();
    },
    onUploadError(err, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();
      this.$alert("导入失败", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      });
    },
    onUploadSuccess(res, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();

      if (res.code == 0) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
      } else if (res.code == 1) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          this.getDataList();
          this.excelImp.dialogVisible = false;
        });
      }
    },
    handleExcelImp() {
      this.excelImp.fileList = [];
      this.excelImp.dialogVisible = true;
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageSize = val;
      this.getDataList();
    },
    init() {
      getUserTypeList().then((res) => {
        if (res.code) {
          let userTypeList = [];
          // userTypeList.push({ name: "请选择嘉宾类型", id: "" });
          // this.userTypeList = res.data;
          // this.userTypeList.unshift({name: "请选择嘉宾类型", id:''})
          for (let i in res.data) {
            if (
              res.data[i].id == 1 ||
              res.data[i].id == 2 ||
              res.data[i].id == 3 ||
              res.data[i].id == 4 ||
              res.data[i].id == 5 ||
              res.data[i].id == 6
            ) {
              userTypeList.push(res.data[i]);
            }
          }

          this.userTypeList = userTypeList;
        }
      });
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
          this.yyCompanyList.unshift({ name: "请选择邀宾单位/部门", id: "" });
        }
      });

      getHotelList().then((res) => {
        if (res.code) {
          this.hotelList = res.data;
          this.hotelList.unshift({ id: "", name: "请选择酒店" });
        }
      });

      getAllUserList().then((res) => {
        if (res.code) {
          this.userList = JSON.parse(JSON.stringify(res.data));
          this.userList2 = JSON.parse(JSON.stringify(res.data));
        }
      });

      // 按照分组获取会议
      getPlateListWithGroup({ isZy: 0 }).then((res) => {
        this.plateWithGroupList = res.data;
        if (res.code) {
          this.tabsActiveName = res.data[0].title;
        }
      });
    },
    // 时间格式化
    dateFormat(row, column) {
      var date = row[column.property] * 1000;
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    // 时间格式化
    dateFormattwo(row, column) {
      var date = row[column.property] * 1000;
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },

    datatimes() {
      this.editForm.endTime = this.value1 / 1000;
      console.log(this.editForm.endTime);
    },
    getAllRole() {
      getAllRole().then((res) => {
        if (res.code == 0) {
          this.$alert("获取角色列表失败", "", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          }).then(() => {
            this.editFormVisible = false;
          });
        } else if (res.code == 1) {
          this.roleList = res.data;
        }
      });
    },
    showAddDialogForm() {
      //if (this.frontUserList && this.frontUserList.length <= 0){
      getFrontUserList().then((res) => {
        if (res.code) {
          this.frontUserList = res.data;
        }
      });
      //}

      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = 0;
      this.editForm.title = "";
      this.editForm.endTime = "";

      this.editFormVisible = true;
      this.roleList = [];
      this.editForm.frontUserId = "";
      this.getAllRole();
    },
    handleEdit(index, row) {
      this.actorScore = row.process;
      this.editFormVisible = true;
      this.editForm.id = row.id;
      // this.editForm.process=row.process
    },
    getDataListwo() {
      const loadingDiv = util.showLoading();

      getParticipateListwo()
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            this.handlerUserList = res.data;
            console.log(res);
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    haEdit(row) {
      if (row.id) {
        this.$refs["edits"].showEdit(row);
      } else {
        this.$refs["edits"].showEdit();
      }
    },
    close() {
      this.editForm = {};
      this.editFormVisible = false;
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "上传中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = shenhe;

          func(this.form)
            .then((res) => {
              let title = "上传";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.close();
                  this.getDataList();
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      console.log(row);
      let loadingDiv;
      this.$confirm("是否确认删除", "删除确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return doDELETE({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList();
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },

    getDataList() {
      const loadingDiv = util.showLoading();

      getList(this.searchForm)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;

            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList();
    },
    handleCurrentChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      this.getDataList();
    },
  },
};
</script>
    
    <style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
    <style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
.fengmiantu {
  width: 50px;
  height: 50px;
}
</style>
    